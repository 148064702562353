import React ,{useState}from "react";
import { Col, } from "reactstrap";
import "../../styles/tour.css";
import PhoneNumberPopup from "./PhoneNumberPopUp";
import { isMobileDevice } from "../../utils/utils";
import { constants } from "../../consts";

const Tour = (props) => {
    const { imgUrl, name, cars } = props.item;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
  
    return (
        <Col lg="4" md="4" sm="6" className="mb-5">
            <PhoneNumberPopup modal={modal} toggle={toggle}></PhoneNumberPopup>

            <div className="tour__item">
                <div className="tour__img">
                    <img src={constants.SERVER_URL+imgUrl} alt="" className="w-100" />
                </div>

                <div className="mt-4" >
                    <h4 className="section__title text-center">{name}</h4>

                    <div className="car-list-container mt-3 d-flex justify-content-center">
                        <ul className="car-list">
                            {Object.keys(cars).map((car) => (
                                <li key={car} className="car-item">
                                    <i className="ri-car-line car-icon"></i>&nbsp;
                                    <span className="car-info">{car}: ₹{cars[car]}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                     <a className="btn-custom"href={`tel:${constants.PhoneNumber}`} onClick={()=>{
            if (!isMobileDevice()) {
              setModal(true);
            }
          }}>Book Now</a>
                </div>
            </div>
        </Col>
    );
};

export default Tour;
