import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col,
} from 'reactstrap';
import { constants } from '../consts';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); // Use the hook here


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${constants.SERVER_URL}/api/login`, {
                email,
                password,
            });

            const { token } = response.data;
            localStorage.setItem(constants.TokenKey, token); // Save token to localStorage or manage it as needed
            alert('Login successful!');
            navigate('/admin'); // Navigate to the /admin route

        } catch (error) {
            localStorage.removeItem(constants.TokenKey)
            setMessage('Invalid email or password');
        }
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md="6">
                    <h2 className="text-center">Login</h2>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormGroup>
                        <Button color="primary" type="submit" block>
                            Login
                        </Button>
                    </Form>
                    {message && <p className="text-center mt-3">{message}</p>}
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
