import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody, CardTitle, CardText } from "reactstrap";
import { getCars, getTours, updateCarRate, updateTourRate, verifyToken } from "../service/requests";
import { constants } from "../consts";
// import "./Admin.css"; // Assuming you create an Admin.css file for styles

const Admin = () => {
    const [carData, setCarData] = useState([]);
    const [tourData, setTourData] = useState([]);
    const [newPrices, setNewPrices] = useState({});
    const [newTourPrices, setNewTourPrices] = useState({});
    const [token, setToken] = useState("");

    const navigate = useNavigate(); // Use the hook here

    useEffect(() => {
        window.scrollTo(0, 0); // Reset scroll position on component mount
        const token = localStorage.getItem(constants.TokenKey);
        if (!token) {
            navigate('/login'); // Navigate to the /login route
        } else {
            verifyToken(token).then(() => {
                setToken(token);
            }).catch(err => {
                navigate('/login'); // Navigate to the /login route
            });
        }
        getCars().then((carData) => setCarData(carData));
        getTours().then((tourData) => setTourData(tourData));
    }, [navigate]); // This effect will only run once when the component mounts

    const handleLogout = () => {
        localStorage.removeItem(constants.TokenKey);
        navigate('/login');
    };

    const handleSubmit = (e, carId) => {
        e.preventDefault();
        const price = newPrices[carId];
        if (price) {
            updateCarRate(token, carId, price).then(updatedCarData => {
                setCarData(updatedCarData);
                setNewPrices({});
            });
        }
    };

    const handleTourSubmit = (e, tourId, carName) => {
        e.preventDefault();
        const price = newTourPrices[tourId] && newTourPrices[tourId][carName];
        if (price) {
            updateTourRate(token, tourId, carName, price).then(updatedTourData => {
                setTourData(updatedTourData);
                setNewTourPrices({});
            });
        }
    };

    const handlePriceChange = (e, carId) => {
        const { value } = e.target;
        setNewPrices(prevPrices => ({
            ...prevPrices,
            [carId]: value
        }));
    };

    const handleTourPriceChange = (e, tourId, carName) => {
        const { value } = e.target;
        setNewTourPrices(prevPrices => ({
            ...prevPrices,
            [tourId]: {
                ...prevPrices[tourId],
                [carName]: value
            }
        }));
    };

    return (
        <Container>
            <Row className="my-4">
                <Col>
                    <Button color="danger" onClick={handleLogout}>Logout</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1 className="my-4">Update Car Prices</h1>
                    {carData.map((car) => (
                        <Card key={car.id} className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Car Name: {car.name}</CardTitle>
                                <CardText>Current Price: {car.carDetails["Price Per km"]}</CardText>
                                <Form onSubmit={(e) => handleSubmit(e, car.id)}>
                                    <FormGroup>
                                        <Label for={`newPrice-${car.id}`}>New Price</Label>
                                        <Input
                                            required
                                            type="number"
                                            name={`newPrice-${car.id}`}
                                            id={`newPrice-${car.id}`}
                                            placeholder="Enter New Price"
                                            value={newPrices[car.id] || ''}
                                            onChange={(e) => handlePriceChange(e, car.id)}
                                        />
                                    </FormGroup>
                                    <Button type="submit" color="primary">Update Price</Button>
                                </Form>
                            </CardBody>
                        </Card>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1 className="my-4">Update Tour Prices</h1>
                    {tourData.map((tour) => (
                        <Card key={tour.id} className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Tour Name: {tour.name}</CardTitle>
                                {Object.keys(tour.cars).map(car => (
                                    <Card key={tour.id + car} className="mb-4">
                                        <CardBody>
                                            <CardText>Car: {car}</CardText>
                                            <CardText>Current Price: {tour.cars[car]}</CardText>
                                            <Form onSubmit={(e) => handleTourSubmit(e, tour.id, car)}>
                                                <FormGroup>
                                                    <Label for={`newTourPrice-${tour.id}-${car}`}>New Price</Label>
                                                    <Input
                                                        required
                                                        type="number"
                                                        name={`newTourPrice-${tour.id}-${car}`}
                                                        id={`newTourPrice-${tour.id}-${car}`}
                                                        placeholder="Enter New Price"
                                                        value={(newTourPrices[tour.id] && newTourPrices[tour.id][car]) || ''}
                                                        onChange={(e) => handleTourPriceChange(e, tour.id, car)}
                                                    />
                                                </FormGroup>
                                                <Button type="submit" color="primary">Update Price</Button>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                ))}
                            </CardBody>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

export default Admin;
