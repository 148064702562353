import React, { useEffect, useState } from "react";
import { Container, Row, } from "reactstrap";
import "../../styles/about-section.css";
// import { tourData} from "../../assets/data/tourData";
import Tour from "../UI/Tour";
import Subtitle from "./SubTitle";
import { getTours } from "../../service/requests";

const ServicesSection = ({ fromHomePage }) => {
  const [tourData, setTourData] = useState([])
  useEffect(() => {
    getTours().then(tourData => {
      setTourData(tourData);
    });
  }, [])
  let length = tourData.length;
  if (fromHomePage) {
    length = 3;
  }
  return (

    <>
      <Subtitle text={"Cabs For Local and Outstation Hire"}></Subtitle>
      <Container>
        <Row className="no-gutters">
          {tourData.slice(0, length,).map((item) => (
            <Tour item={item} key={item.id} />
          ))}
        </Row>
      </Container>
    </>
  );
};

export default ServicesSection;
