import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
// import CarListing from "../pages/CarListing";
// import CarDetails from "../pages/CarDetails";
import Gallery from "../pages/Gallery";
// import BlogDetails from "../pages/BlogDetails";
// import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import Services from "../pages/Services";
import Admin from "../pages/Admin";
import Login from "../pages/Login";


const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />

      {/* <Route path="/cars" element={<CarListing />} /> */}
      {/* <Route path="/cars/:slug" element={<CarDetails />} /> */}
      <Route path="/gallery" element={<Gallery />} />
      {/* <Route path="/gallery/:slug" element={<BlogDetails />} /> */}
      <Route path="/contact" element={<Contact />} />
      <Route path="/admin" element={<Admin />}/>
      <Route path="/login" element={<Login />}/>

      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default Routers;
