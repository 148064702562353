import React from "react";

const Subtitle = ({ text }) => (
    <h1 style={styles.subtitle}> <span>&#8226;</span> {text} <span>&#8226;</span></h1>
);

const styles = {
    subtitle: {
        fontSize: '2em',
        fontWeight: '600',
        color: '#000',
        marginTop: '10px',
        textAlign:'center'
    },
};

export default Subtitle;
