import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
// import Testimonial from "../components/UI/Testimonial";
// import BlogList from "../components/UI/BlogList";
import ServicesSection from "../components/UI/ServicesSection";
import { Separator } from "../components/UI/Seperator";
// import GoogleReviews from "../components/GoogleReviews/Reviews"; 
import { Link } from "react-router-dom";
import "../styles/home.css";
import Title from "../components/UI/Title";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position on component mount
  }, []); // This effect will only run once when the component mounts

  return (
    <Helmet title="Home">
      {/* ============= hero section =========== */}
      <section className="p-0 hero__slider-section">
        <HeroSlider />
        <Separator />
        <div className="hero__form">
          <Container>
            <Row className="text-center">
              <Col>
                <Title text={"Services"} />
              </Col>
            </Row>
            <Row className="no-gutters">
              <ServicesSection fromHomePage="true" />
            </Row>
            <Row className="no-gutters">
              <Col className="text-center ">
                <Link className="btn btn-lg btn-primary btn-more" to="/services">View More</Link>
              </Col>
            </Row>

          </Container>
        </div>
      </section>
      <Separator />

      {/* ========== services section ============ */}


      {/* =========== about section ================ */}

      <Container>
        <Row className="text-center">
          <Col>
            <Title text={"About us"} />
          </Col>
        </Row>
        <Row className="no-gutters">
          <AboutSection />
        </Row>
        <Row className="no-gutters">
          <Col className="text-center ">
            <Link aria-label="Read more about quick savaari" className="btn btn-lg btn-primary btn-more" to="/about">Read More</Link>
          </Col>
        </Row>
      </Container>

      <Separator />

      {/* =========== testimonial section =========== */}
      {/* <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle">Our clients say</h6>
              <h2 className="section__title">Testimonials</h2>
            </Col>
            <Testimonial />
          </Row>
        </Container>
      </section> */}

      {/* =============== blog section =========== */}
      {/* <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Explore our blogs</h6>
              <h2 className="section__title">Latest Blogs</h2>
            </Col>
            <BlogList />
          </Row>
        </Container>
      </section> */}  
      {/* <Container> 
      
      <Row className="text-center">
          <Col>
            <Title text={"Google Reviews"} />
          </Col>
        </Row>
        <Row className="no-gutters">
        <GoogleReviews placeId={"ChIJN1t_tDeuEmsRUsoyG83frY4"} apiKey={"AIzaSyDw0moKN5y_95_oEj4Phg1o5K-7Fi-k0ho"} />
        </Row>
      </Container> 
      <Separator /> */}
    </Helmet>
  );
};

export default Home;
