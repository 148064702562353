import React from "react";

const Title = ({ text }) => (
    <div style={styles.container}>
        <h2 style={styles.heading}>{text}</h2>
    </div>
);

const styles = {
    container: {
        margin: '20px 0',
        textAlign: 'center',
    },
    heading: {
        fontSize: '2em',
        fontWeight: 'bold',
        color: '#333',
    }
};

export default Title;
