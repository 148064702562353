import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";
// import carData from "../assets/data/carData";
import ServicesSection from "../components/UI/ServicesSection";
import Subtitle from "../components/UI/SubTitle";
import { getCars } from "../service/requests";

const Services = () => {
  const [carData, setCarData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position on component mount
    getCars().then((carData) => setCarData(carData));
  }, []); // This effect will only run once when the component mounts

  return (
    <Helmet title="Services">
      <CommonSection title="Services" />
      <ServicesSection />
        <Container>
          <Subtitle text="Outstation Car Services" />
          <Row>
            {carData.map((item) => (
              <CarItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
    </Helmet>
  );
};

export default Services;
