import React from 'react';
import '../../styles/map.css';

const MapComponent = () => {
  return (
    <div className="map-container">
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.051091507193!2d73.91102060000001!3d18.4813448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebc60df6d76b%3A0x7f3616ea54950031!2sQuick%20savaari!5e0!3m2!1sen!2sin!4v1720004330828!5m2!1sen!2sin"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* <div className="info-card">
        <div>
          <p style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '16px' }}>
            Quick savaari
          </p>
          <p style={{ fontSize: '14px', lineHeight: '1.4', fontWeight: 400 }}>
            Heaven Park Rd, Jarande Nagar, Mohammed Wadi, Pune, Maharashtra 411060                                    <br />
          </p>
          <div className="view-link poi-info-window" style={{ fontWeight: 400 }}>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Quick+savaari/@18.4813499,73.9084457,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2ebc60df6d76b:0x7f3616ea54950031!8m2!3d18.4813448!4d73.9110206!16s%2Fg%2F11w1vwpxz_?entry=ttu"
              tabIndex="0"
              rel="noreferrer"
            >
              <span>View on Google Maps</span>
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default MapComponent;
