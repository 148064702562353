import axios, { AxiosHeaders } from "axios";
import { constants } from "../consts";

const { SERVER_URL } = constants;

export const getCars = async () => {
  const res = await axios.get(`${SERVER_URL}/cars`);
  return res.data;
};

export const getTours = async () => {
  const res = await axios.get(`${SERVER_URL}/tours`);
  return res.data;
};

export const updateCarRate = async (token,id, rate) => {
    const headers = new AxiosHeaders();
    headers.setAuthorization(token)

  const res = await axios.put(`${SERVER_URL}/cars/${id}`,{
    newRate:rate
  },{headers});
  return res.data;
};

export const sendMail = async (body) => {
const res = await axios.post(`${SERVER_URL}/mail/send-email`,body);
return res.data;
};


export const updateTourRate = async (token, id, carName, rate) => {
    const headers = new AxiosHeaders();
    headers.setAuthorization(token)

  const res = await axios.put(`${SERVER_URL}/tours/${id}`, {
    carName,
    newRate: rate,
  },{headers});
  return res.data;
};

export const verifyToken = async (token) => {
    const headers = new AxiosHeaders();
    headers.setAuthorization(token)

    const res = await axios.get(`${SERVER_URL}/api/verify`,{
        headers
    })
    return res.data;
  };
  
