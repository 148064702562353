import React, { useState } from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import { constants } from "../../consts";
import PhoneNumberPopup from "../UI/PhoneNumberPopUp";
import { isMobileDevice } from "../../utils/utils";

const quickLinks = [
  {
    path: "/home",
    display: "Home"
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/services",
    display: "Services",
  },
  {
    path: "/gallery",
    display: "Gallery",
  },
  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className="footer">
      <PhoneNumberPopup modal={modal} toggle={toggle}></PhoneNumberPopup>

      <Container>
        <Row>
          <Col lg="6" md="4" sm="12">
            <p className="footer__logo-content">
              We are passionate about sustainability and are committed to reducing our environmental footprint. Our fleet includes fuel-efficient and eco-friendly vehicles to help you travel greener. We continuously seek ways to improve our operations and promote responsible travel.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              {isMobileDevice() ? (
          <div className="d-flex flex-row gap-2">
            {quickLinks.map((item, index) => (
              <div key={index} className="quick__link">
                <Link to={item.path}>{item.display}</Link>
              </div>
            ))}
          </div>
        ) : (
          <ListGroup>
            {quickLinks.map((item, index) => (
              <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                <Link to={item.path}>{item.display}</Link>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
            </div>
          </Col>

          <Col lg="4" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">{constants.CompanyName}, Vinaya Building, Line Number 3, Heaven Park, House No. 944/1, Survey No. 47, Mohammadwadi, Pune, Maharashtra, 411060, India.</p>
              <p className="office__info">Phone: <a href={`tel:${constants.PhoneNumber}`} onClick={() => {
                if (!isMobileDevice()) {
                  setModal(true)
                }
              }}>{constants.PhoneNumber}</a></p>
              <p className="office__info">Email: <a href={`mailto:${constants.Email}`}>{constants.Email}</a></p>
              <p className="office__info">Office Time: 10am - 7pm</p>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i className="ri-copyright-line"></i>Copyright {year}, {constants.CompanyName} | All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
