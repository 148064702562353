import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Carousel, CarouselItem, CarouselControl, TabPane, TabContent, CarouselIndicators } from 'reactstrap';
import CommonSection from "../components/UI/CommonSection";

import img01 from "../assets/all-images/blog-img/blog-1.webp";
import img02 from "../assets/all-images/blog-img/blog-2.webp";
import img03 from "../assets/all-images/blog-img/blog-3.webp";
import img04 from "../assets/all-images/drive.webp";
import img05 from "../assets/all-images/tours-img/s1c.webp";
import img06 from "../assets/all-images/tours-img/s3c.webp";
import img07 from "../assets/all-images/tours-img/s5c.webp";
import img09 from "../assets/all-images/custom-img/Aura.webp";
import img10 from "../assets/all-images/custom-img/Ertiga.webp";
import img11 from "../assets/all-images/custom-img/Fort.webp";
import img12 from "../assets/all-images/custom-img/Innova.webp";
import img13 from "../assets/all-images/custom-img/Kalu.webp";
import img14 from "../assets/all-images/custom-img/KaluWaterFall.webp";
import img15 from "../assets/all-images/custom-img/Kokankada.webp";
import img16 from "../assets/all-images/custom-img/Lonavala.webp";
import img17 from "../assets/all-images/custom-img/Mahabaleshwar.webp";
import img18 from "../assets/all-images/custom-img/Nature.webp";
import img19 from "../assets/all-images/custom-img/Nature1.webp";
import img20 from "../assets/all-images/custom-img/Nature2.webp";
import img21 from "../assets/all-images/custom-img/Nature3.webp";
import img22 from "../assets/all-images/custom-img/Nature4.webp";
import img23 from "../assets/all-images/custom-img/Swift.webp";
import img24 from "../assets/all-images/custom-img/fort1.webp";
import img25 from "../assets/all-images/custom-img/fort2.webp";
import "../styles/gallery.css"; // Import custom CSS for gallery styling
import Subtitle from '../components/UI/SubTitle';

const photos = [
  { id: 1, src: img01, alt: 'Nissan Offer', key: 1 },
  { id: 2, src: img02, alt: 'Toyota Offer', key: 2 },
  { id: 3, src: img03, alt: 'BMW Offer', key: 3 },
  { id: 4, src: img04, alt: 'Nissan Offer', key: 4 },
  { id: 5, src: img05, alt: 'Toyota Offer', key: 5 },
  { id: 6, src: img06, alt: 'Mercedes Offer', key: 6 },
  { id: 7, src: img07, alt: 'Toyota Offer 2', key: 7 },
  { id: 9, src: img09, alt: 'Aura', key: 9 },
  { id: 10, src: img10, alt: 'Ertiga', key: 10 },
  { id: 11, src: img11, alt: 'Fort', key: 11 },
  { id: 12, src: img12, alt: 'Innova', key: 12 },
  { id: 13, src: img13, alt: 'Kalu', key: 13 },
  { id: 14, src: img14, alt: 'Kalu Waterfall', key: 14 },
  { id: 15, src: img15, alt: 'Kokankada', key: 15 },
  { id: 16, src: img16, alt: 'Lonavala', key: 16 },
  { id: 17, src: img17, alt: 'Mahabaleshwar', key: 17 },
  { id: 18, src: img18, alt: 'Nature', key: 18 },
  { id: 19, src: img19, alt: 'Nature 1', key: 19 },
  { id: 20, src: img20, alt: 'Nature 2', key: 20 },
  { id: 21, src: img21, alt: 'Nature 3', key: 21 },
  { id: 22, src: img22, alt: 'Nature 4', key: 22 },
  { id: 23, src: img23, alt: 'Swift', key: 23 },
  { id: 24, src: img24, alt: 'Fort 1', key: 24 },
  { id: 25, src: img25, alt: 'Fort 2', key: 25 }
];


const Gallery = () => {
  const wrapperRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const [activeImage, setActiveImage] = useState(null);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === photos.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? photos.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleImageClick = (index) => {
    setActiveImage(index);
  }

  const closeImage = () => {
    setActiveImage(null);
  }
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position on component mount
  }, []); // This effect will only run once when the component mounts

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // Click occurred outside of the image container, close it
        closeImage();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeImage]);

  const slides = photos.map(photo => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={photo.id}
      >
        <img src={photo.src} alt={photo.alt} className=" w-100" />
      </CarouselItem>
    );
  });

  return (
    <>
      <CommonSection title="Gallery" />

      <Container className="gallery-container">
        <Row className='text-center'>
          <Subtitle text={"What we’re offering"
          }></Subtitle>
        </Row>
        <Row>
          <Col >
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className='gallery-carousel'
            >
              <CarouselIndicators
                items={photos}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
                className='carousel-control'
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
                className='carousel-control'
              />
            </Carousel>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="12">
            <TabContent>
              <TabPane>
                <Row className="photo-row">
                  {photos.map((photo, index) => (
                    <Col key={photo.id} xs="6" sm="4" md="4" lg="4" className="photo-col">
                      <img
                        src={photo.src}
                        alt={photo.alt}
                        className="img-fluid photo-img"
                        onClick={() => handleImageClick(index)}
                      />
                    </Col>
                  ))}
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
      {activeImage !== null && (
        <div className="image-popup">
          <div ref={wrapperRef} className="image-popup-content">
            <img src={photos[activeImage].src} alt={photos[activeImage].alt} className="popup-img" />
            <button className="close-btn" onClick={closeImage}><span>&times;</span></button>
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;
