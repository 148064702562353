import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import "../../styles/phone-number-popup.css";
import { constants } from '../../consts';

const PhoneNumberPopup = ({ modal, toggle }) => {
  return (
    <div className="d-flex justify-content-center">
      <Modal isOpen={modal} toggle={toggle} className="custom-modal">
        <ModalHeader toggle={toggle} className="modal-header">Contact Number</ModalHeader>
        <ModalBody className="modal-body">
          <p className="phone-number">Phone Number: <span className="number">{constants.PhoneNumber}</span></p>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button color="secondary" onClick={toggle} className="close-btn">Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PhoneNumberPopup;
