import React, { useRef, useEffect, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";
import PhoneNumberPopup from "../UI/PhoneNumberPopUp";
import { isMobileDevice } from "../../utils/utils";
import { constants } from "../../consts";

const navLinks = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/services",
    display: "Services",
  },
  {
    path: "/gallery",
    display: "Gallery",
  },
  {
    path: "/contact",
    display: "Contact",
  },
];

const Header = () => {
  const menuRef = useRef(null);
  const headerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > headerRef.current.offsetTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      const headerHeight = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty("--header-height", `${headerHeight}px`);
    };

    window.addEventListener("scroll", handleScroll);

    const headerHeight = headerRef.current.offsetHeight;
    document.documentElement.style.setProperty("--header-height", `${headerHeight}px`);
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    const menuActive = menuRef.current;
    menuActive.classList.toggle("menu__active");
  };

  return (
    <>
      <header className={`header ${isSticky ? "sticky" : ""}`} ref={headerRef}>
        {/* Header Middle */}
        <div className="header__middle">
          <Container>
            <PhoneNumberPopup modal={modal} toggle={toggle} />
            <Row className="align-items-center">
              <Col lg="2" md="4" sm="4" xs="4">
                <div className="logo">
                  <Link to="/home" className="d-flex align-items-center gap-2">
                    <img src="./image.svg" style={{ width: "100%" }} alt="Logo" />
                  </Link>
                </div>
              </Col>
              <Col lg="3" md="4" sm="4" xs="4">
                <div className="header__location d-flex align-items-center gap-2">
                  <span>
                    <i className="ri-earth-line"></i>
                  </span>
                  <div className="header__location-content">
                    <h4>Pune</h4>
                    <h6>Maharashtra, India</h6>
                  </div>
                </div>
              </Col>
              <Col lg="2" md="4" sm="4" xs="4">
                <div className="header__location d-flex align-items-center gap-2">
                  <span>
                    <i className="ri-time-line"></i>
                  </span>
                  <div className="header__location-content">
                    <h4>24x7</h4>
                  </div>
                </div>
              </Col>
              {
                !isMobileDevice() &&
                <Col lg="2" className="header__location d-flex align-items-center gap-2">
                  <a className="d-flex align-items-center"
                    href={`mailto:${constants.Email}`}
                  >
                    <i className="ri-mail-fill"></i><div className="header__location-content">
                      <h4>{constants.Email}</h4>
                    </div>
                  </a>
                </Col>
              }
              {/* <Col lg="2" md="4" sm="4" xs="4" className="d-flex align-items-center justify-content-end">
                <button className="header__btn btn btn-primary">
                  <a
                    href={`tel:${constants.PhoneNumber}`}
                    className="text-white text-decoration-none"
                    onClick={() => {
                      if (!isMobileDevice()) {
                        setModal(true);
                      }
                    }}
                  >
                    <i className="ri-phone-line"></i> Book Now
                  </a>
                </button>
              </Col> */}
            </Row>
          </Container>
        </div>

        {/* Main Navbar */}
        <div className="main__navbar">
          <Container>
            <div className="navigation__wrapper d-flex align-items-center justify-content-between">
              <span className="mobile__menu">
                <i className="ri-menu-line" onClick={toggleMenu}></i>
              </span>

              <div className="navigation" ref={menuRef} onClick={toggleMenu}>
                <div className="menu">
                  {navLinks.map((item, index) => (
                    <NavLink
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active nav__item" : "nav__item"
                      }
                      key={index}
                    >
                      {item.display}
                    </NavLink>
                  ))}
                </div>
              </div>
              <div className="call-button">
                <a href={`tel:${constants.PhoneNumber}`} className="btn btn-primary" onClick={() => {
                  if (!isMobileDevice()) {
                    setModal(true);
                  }
                }}>
                  Book Now
                </a>
              </div>
            </div>
          </Container>
        </div>
      </header>

      {/* Static WhatsApp and Call Icons */}
      <div className="static-icons">
        <a href={`https://wa.me/${constants.PhoneNumber.split("-")[1]}`} target="_blank" aria-label="message on whatsapp" rel="noopener noreferrer">
          <i className="ri-whatsapp-line"></i>
        </a>
        <a href={`tel:${constants.PhoneNumber}`} className="call-icon" onClick={() => {
          if (!isMobileDevice()) {
            setModal(true);
          }
        }} aria-label="call to quick savaari">
          <i className="ri-phone-line"></i>
        </a>
      </div>
    </>
  );
};

export default Header;
