import React, { useEffect, useState } from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";

import driveImg from "../assets/all-images/drive.webp";
import "../styles/about.css";
import { isMobileDevice } from "../utils/utils";
import PhoneNumberPopup from "../components/UI/PhoneNumberPopUp";
import { constants } from "../consts";


const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position on component mount
  }, []); // This effect will only run once when the component mounts
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <Helmet title="About">
      <PhoneNumberPopup modal={modal} toggle={toggle}></PhoneNumberPopup>

      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  We Are Committed To Provide Safe Ride Solutions
                </h2>

                <p className="section__description">
                  We are passionate about sustainability and are committed to reducing our environmental footprint. Our fleet includes fuel-efficient and eco-friendly vehicles to help you travel greener. We continuously seek ways to improve our operations and promote responsible travel.
                </p>

                <p className="section__description">
                  we are more than just a car rental service – we are your travel companion. Whether you're exploring a new city, heading to a business meeting, or embarking on a road trip, we are here to make your journey smooth and memorable.
                </p>

                <div className=" d-flex align-items-center mt-4">


                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>

                    <h4 className="">
                      <a href={`tel:${constants.PhoneNumber}`} className="d-flex align-items-center text-decoration-none gap-2" onClick={() => {
                        if (!isMobileDevice()) {
                          setModal(true)
                        }
                      }}>
                        <i className="ri-phone-line"></i>
                        {constants.PhoneNumber}                </a>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
