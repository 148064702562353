import React, { useEffect, useState } from "react";

import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";

import "../styles/contact.css";
import MapComponent from "../components/Map/Map";
import { constants } from "../consts";
import { isMobileDevice } from "../utils/utils";
import PhoneNumberPopup from "../components/UI/PhoneNumberPopUp";
import emailjs from '@emailjs/browser';
import { Separator } from "../components/UI/Seperator";


const socialLinks = [
  {
    url: "https://www.facebook.com/people/Quicksavaari-Savaari/pfbid02u7DvyJ9WDtpNNmix6Suxdqyhw359zJYUvWqWS56PeD4qn5nT9ouGNrc9bMv3D8T8l/?mibextid=ZbWKwL",
    icon: "ri-facebook-line",
  },
  {
    url: "https://www.instagram.com/quicksavaari4040?igsh=MWVzdTBkZTU3NHZsbA==",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);


  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position on component mount
  }, []); // This effect will only run once when the component mounts
  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: e.target.name.value,
      email: e.target.email.value,
      reply_to: e.target.email.value,
      mobileNumber: e.target.mobile.value,
      message: e.target.message.value
    };
    emailjs.send(
      'service_hcm37yr', // Replace with your EmailJS service ID
      'template_cf3ktzp', // Replace with your EmailJS template ID
      templateParams,
      {publicKey:"CMiZ7bhGzxy8ofbmA"}
    )
    .then((result) => {
      console.log('Email successfully sent!', result.text);
    }, (error) => {
      console.log('Failed to send the email.', error.text);
    });
   // Clear the form fields after submission (optional)
    e.target.reset();
  };
  return (
    <Helmet title="Contact">
      <PhoneNumberPopup modal={modal} toggle={toggle}></PhoneNumberPopup>

      <CommonSection title="Contact" />
        <Container>
          <Row>
            <MapComponent />
          </Row>
        </Container>
        <Separator/>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h4 className="fw-bold mb-4">Get In Touch</h4>
              <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <Input name="name" placeholder="Your Name" type="text" className="form-control" required />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Input name="email" placeholder="Email" type="email" className="form-control" required />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Input name="mobile" placeholder="Mobile Number" type="tel" pattern="[7-9][0-9]{9}" title="Please enter a 10-digit mobile number starting with 7, 8, or 9" className="form-control" required />
                </FormGroup>
                <FormGroup className="mb-4">
                  <textarea name="message" rows="5" placeholder="Message" className="form-control" required></textarea>
                </FormGroup>
                <button className="btn btn-primary mb-4" type="submit">
                  Send Message
                </button>
              </Form>
            </Col>
            <Col lg="5" md="5">
              <div className="contact__info">
                <h4 className="fw-bold">Contact Information</h4>
                <p className="mb-3">123 Pune, Maharashtra, India</p>
                <div className="mb-3">
                  <h5 className="fs-6 mb-0">Phone:</h5>
                  <p className="mb-0"><a href={`tel:${constants.PhoneNumber}`} onClick={() => {
                    if (!isMobileDevice()) {
                      setModal(true)
                    }
                  }}>{constants.PhoneNumber}</a></p>
                </div>
                <div className="mb-3">
                  <h5 className="fs-6 mb-0">Email:</h5>
                  <p className="mb-0"><a href={`mailto:${constants.Email}`}>{constants.Email}</a></p>
                </div>
                <h5 className="fw-bold mt-4">Follow Us</h5>
                <div className="d-flex align-items-center gap-4 mt-3">
                  {/* Assuming socialLinks is an array of objects containing social links and icons */}
                  {/* You may need to adjust the styles for social icons based on your design */}
                  {socialLinks.map((item, index) => (
                    <a href={item.url} key={index} className="social__link-icon">
                      <i className={item.icon}></i>
                    </a>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    </Helmet>
  );
};

export default Contact;
