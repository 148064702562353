import React from "react";

export const Separator = () => (
    <hr
        style={{
            backgroundColor: 'transparent',
            height: '5px',
            border: 'none',
            margin: '30px 0',
            backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0), black, rgba(0,0,0,0))',
            borderRadius: '5px'
        }}
    />
);
